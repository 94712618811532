<template lang="pug">
    .language
        img.close(
            src="@/assets/icon/close-icon.svg",
            @click="$store.commit('mobile-layout/showLanguageView', false)"
        )
        .language-list-block(v-if="languageList")
            .language-row(
                :class="{ selected: language.code == userLanguage }",
                v-for="language in languageList",
                @click="updateUserLanguage(language)"
            )
                img(:src="language.icon")
                p.title-3.label {{ language.label }}
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    computed: {
        languageList() {
            let toReturn = [];
            const languageList = this.$store.getters["language/getLanguageList"]
            if (languageList) {
                Object.keys(languageList).forEach((key) => {
                    let language = languageList[key];
                    toReturn.push(language);
                });
            }
            return toReturn;
        },
        userLanguage() {
            return this.$store.getters["language/getUserLanguage"]
        },
    },

    methods: {
        updateUserLanguage(language) {
            this.$store.dispatch("language/updateUserLanguage", language.code).then(response => {

            })
            this.$store.commit('mobile-layout/showLanguageView', false)
        },
    },

    mounted() {
        this.$store.dispatch("language/fetchLanguageList")
    },
});
</script>

<style lang="scss" scoped>
.language {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 4000;
    background: white;

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        filter: var(--svg-filter-text-color);
    }

    .language-list-block {
        margin-top: 20vh;
        margin-left: 60px;
        margin-right: 60px;

        .language-row {
            width: 100%;
            display: flex;
            align-items: center;
            padding-top: 18px;
            padding-bottom: 18px;

            img {
                margin-right: 25px;
                width: 25px;
                height: 25px;
                margin-left: 40px;
            }

            &.selected {
                box-shadow: 0px 0px 20px var(--shadow-color);
            }
        }
    }
}
</style>